define("doyle-resume/components/nav-bar-full", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <nav class="nav-container-full">
    <ul>
      <a class="nav-link" href="#about-me-section">ABOUT ME</a>
      <a class="nav-link" href="#knowledge-base-section">KNOWLEDGE BASE</a>
      <a class="nav-link" href="#projects-section">PROJECTS</a>
      <a class="nav-link" href="/assets/images/doylebrookeresume.pdf">RESUME</a>
      <a class="nav-link" href="#contact-section">CONTACT</a>
    </ul>
    <br>
    <div class="nav-logo-links">
      <a  class="nav-logo-item"href="https://www.linkedin.com/in/doylebrooke/"><img alt="linkedin-logo"class="logo-link"src="assets\images\linkedin.png"></a>
      <a class="nav-logo-item" href="https://github.com/dookebroyle"><img alt="github-logo"class="logo-link" src="assets\images\github.png"></a>
      <a  class="nav-logo-item"href="mailto:brookedoyle13@gmail.com"><img alt="email-logo"class="logo-link" src="assets\images\email.png"></a>
    </div>
  </nav>
  
  */
  {
    "id": "o/9Ym7Dm",
    "block": "[[[10,\"nav\"],[14,0,\"nav-container-full\"],[12],[1,\"\\n  \"],[10,\"ul\"],[12],[1,\"\\n    \"],[10,3],[14,0,\"nav-link\"],[14,6,\"#about-me-section\"],[12],[1,\"ABOUT ME\"],[13],[1,\"\\n    \"],[10,3],[14,0,\"nav-link\"],[14,6,\"#knowledge-base-section\"],[12],[1,\"KNOWLEDGE BASE\"],[13],[1,\"\\n    \"],[10,3],[14,0,\"nav-link\"],[14,6,\"#projects-section\"],[12],[1,\"PROJECTS\"],[13],[1,\"\\n    \"],[10,3],[14,0,\"nav-link\"],[14,6,\"/assets/images/doylebrookeresume.pdf\"],[12],[1,\"RESUME\"],[13],[1,\"\\n    \"],[10,3],[14,0,\"nav-link\"],[14,6,\"#contact-section\"],[12],[1,\"CONTACT\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"br\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"nav-logo-links\"],[12],[1,\"\\n    \"],[10,3],[14,0,\"nav-logo-item\"],[14,6,\"https://www.linkedin.com/in/doylebrooke/\"],[12],[10,\"img\"],[14,\"alt\",\"linkedin-logo\"],[14,0,\"logo-link\"],[14,\"src\",\"assets\\\\images\\\\linkedin.png\"],[12],[13],[13],[1,\"\\n    \"],[10,3],[14,0,\"nav-logo-item\"],[14,6,\"https://github.com/dookebroyle\"],[12],[10,\"img\"],[14,\"alt\",\"github-logo\"],[14,0,\"logo-link\"],[14,\"src\",\"assets\\\\images\\\\github.png\"],[12],[13],[13],[1,\"\\n    \"],[10,3],[14,0,\"nav-logo-item\"],[14,6,\"mailto:brookedoyle13@gmail.com\"],[12],[10,\"img\"],[14,\"alt\",\"email-logo\"],[14,0,\"logo-link\"],[14,\"src\",\"assets\\\\images\\\\email.png\"],[12],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "doyle-resume/components/nav-bar-full.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});