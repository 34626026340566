define("doyle-resume/components/project-photo", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isLarge}}
    <button type="button" class="proj-img-area" class="image large" {{on "click" this.toggleSize}}>
      <img ...attributes class="shadow"><br>
      <small>Click to Toggle Size</small>
    </button>
  {{else}}
  
    <button type="button" class="proj-img-area" class="image" {{on "click" this.toggleSize}}>
      <img ...attributes class="shadow"><br>
      <small>Click to Toggle Size</small>
    </button>
  {{/if}}
  */
  {
    "id": "0bMl5Xpw",
    "block": "[[[41,[30,0,[\"isLarge\"]],[[[1,\"  \"],[11,\"button\"],[24,0,\"proj-img-area\"],[24,0,\"image large\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"toggleSize\"]]],null],[12],[1,\"\\n    \"],[11,\"img\"],[17,1],[24,0,\"shadow\"],[12],[13],[10,\"br\"],[12],[13],[1,\"\\n    \"],[10,\"small\"],[12],[1,\"Click to Toggle Size\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"\\n  \"],[11,\"button\"],[24,0,\"proj-img-area\"],[24,0,\"image\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"toggleSize\"]]],null],[12],[1,\"\\n    \"],[11,\"img\"],[17,1],[24,0,\"shadow\"],[12],[13],[10,\"br\"],[12],[13],[1,\"\\n    \"],[10,\"small\"],[12],[1,\"Click to Toggle Size\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&attrs\"],false,[\"if\",\"on\"]]",
    "moduleName": "doyle-resume/components/project-photo.hbs",
    "isStrictMode": false
  });

  let ProjectPhotoComponent = (_class = class ProjectPhotoComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isLarge", _descriptor, this);
    }

    toggleSize() {
      this.isLarge = !this.isLarge;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isLarge", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleSize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSize"), _class.prototype)), _class);
  _exports.default = ProjectPhotoComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ProjectPhotoComponent);
});