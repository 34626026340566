define("doyle-resume/components/tech-tile", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flip-container">
    <div class="card-content">
        <img ...attributes>
    </div>
  </div>
  
  
  */
  {
    "id": "j4oRGXMA",
    "block": "[[[10,0],[14,0,\"flip-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card-content\"],[12],[1,\"\\n      \"],[11,\"img\"],[17,1],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[\"&attrs\"],false,[]]",
    "moduleName": "doyle-resume/components/tech-tile.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});